import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Login from './pages/Login/Login';
import Manual from './pages/Manual/Manual';
import Landing from './pages/Landing/Landing';

function Routes() {
    const [user, setUser] = useState();
    const [clave, setClave] = useState(); 

    useEffect(() => {
        const key = window.localStorage.getItem('userCode');
        if(key) setClave(key);
    }, []);

    const putUserInState = (user) => {
        setClave(user.access_code);
        setUser(user);
        window.localStorage.setItem('userCode', user.access_code);
    }

    const clearUserInState = () => {
        setClave(null);
        setUser(null);
        window.localStorage.removeItem('userCode');
    }

    return (
        <Switch>
            <Route path="/login" exact render={() => <Login user={user} clave={clave} setUser={putUserInState} handleClear={clearUserInState}/>} />
            <Route path="/manual" exact render={() => <Manual user={user} clave={clave} setUser={putUserInState} handleClear={clearUserInState}/>} />
            <Route path="/landing" exact render={() => <Landing />} />
            <Redirect from="/*" to="/login" />
        </Switch>
    );
}

export default Routes;