import React from 'react';
import './Button.scss';

const Button = (props) => {
  const button = !props.Submit ? <button type="button" onClick={props.onClick} className={`Button ${props.size} ${props.position} Button__${props.type}`}>{props.children}</button> : <button type="submit" onClick={props.onClick} className={`Button ${props.size} Button__${props.type}`}>{props.children}</button>;
  return (
    <div className="Button__Container">
      {button}
    </div>
  )
};

export default Button;