import React from 'react';

import './NavBar.scss';

const NavBar = (props) => {
    return (
        <nav className="NavBar__Container">
            <div className="NavBar__Logo">
                <div className="NavBar__Hamburger">
                    <i className="fas fa-bars" onClick={props.setMobileOpen}></i>
                </div>
                <a href="https://acromatico.dev" target="__blank">
                    <img src="/assets/images/logo-white.png" alt="Acromático Development Small Logo"/>
                </a>
            </div>
        </nav>
    )
}

export default NavBar;