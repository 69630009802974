import React, {Component} from 'react';
import './PopUpMessage.scss'

class PopUpMessage extends Component {

  render(){
    let showClass = this.props.isOpen ? '' : 'PopUpMessage__Hidden';
    return (
      <div className={`PopUpMessage__Container ${showClass}`}>
        <div className="PopUpMessage__Box">
          <h2 className="PopUpMessage__Title">{this.props.title}<i className="fas fa-times" onClick={this.props.handleClose}></i></h2>
          <div className="PopUpMessage__Text">{this.props.children}</div>
        </div>
      </div>
    );
  }
};

export default PopUpMessage;