import React, { useState, useCallback } from "react";
import { withRouter, Link } from "react-router-dom";
import "./Landing.scss";
import Card from "../../components/Card/Card";
import Button from "../../components/Button/Button";
import PopUpMessage from "../../components/PopUpMessage/PopUpMessage";

function Landing(props) {
  const [formVal, setFormVal] = useState({
    name: "",
    email: "",
    product: "",
  });
  const [PopUp, setPopUp] = useState({
    isOpen: false,
    title: "",
    message: <p></p>,
  });
  const [sendingData, setSending] = useState(false);

  const handleChange = useCallback(
    (name, value) => {
      const newState = { ...formVal };

      newState[name] = value;

      setFormVal(newState);
    },
    [formVal]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      setSending(true);

      try {
        const dataReceived = await fetch(
          "https://local-delivery-app.acromati.co/mailgun/save-manual-lead",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formVal),
          }
        ).then((json) => json.json());

        if (dataReceived.access_code || dataReceived.exists) {
          setPopUp({
            isOpen: true,
            title: "Registro Exitoso",
            message: (
              <p>
                ¡Hemos enviado tu código de acceso a tu correo electrónico! Para
                accesar haz click en la siguiente liga: <br />{" "}
                <Link to="/login">https://manual.acromatico.co/login</Link>
              </p>
            ),
          });
          setSending(false);
        } else {
          throw new Error("Error desconocido");
        }
      } catch (error) {
        console.log(error);
        setPopUp({
          isOpen: true,
          title: "Error",
          message: <p>Ocurrió un error inesperado, intente más tarde!</p>,
        });
        setSending(false);
      }
    },
    [formVal]
  );

  return (
    <div className="Login__Container">
      <div className="Login__Form-Container">
        <Card>
          <div className="Login__Form">
            <h1>Crea una tienda en línea en Shopify</h1>
            <p>Llena tus datos para obtener acceso a la guía del curso</p>
            <form className="ac-form" onSubmit={handleSubmit}>
              <div className="ac-form-field">
                <label htmlFor="name">Nombre Completo</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={formVal.name}
                  onChange={(e) => handleChange("name", e.target.value)}
                />
              </div>
              <div className="ac-form-field">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formVal.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                />
              </div>
              <div className="ac-form-field">
                <label htmlFor="prod">¿Qué Quieres Vender en Línea?</label>
                <input
                  type="text"
                  name="prod"
                  id="prod"
                  value={formVal.product}
                  onChange={(e) => handleChange("product", e.target.value)}
                />
              </div>
              {sendingData ? (
                <p>Enviando Datos...</p>
              ) : (
                <Button Submit type="Primary">
                  Entrar
                </Button>
              )}
            </form>
          </div>
        </Card>
      </div>
      <PopUpMessage
        isOpen={PopUp.isOpen}
        title={PopUp.title}
        handleClose={() =>
          setPopUp({ isOpen: false, title: "", message: <p></p> })
        }
      >
        {PopUp.message}
      </PopUpMessage>
    </div>
  );
}

export default withRouter(Landing);
