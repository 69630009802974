import React from "react";

import "./LateralMenu.scss";

import Button from "../../components/Button/Button";

const LateralMenu = (props) => {
  return (
    <section
      className={`LateralMenu__Container ${props.open ? "open-menu" : ""}`}
    >
      <ul>
        <li>
          <img src="https://firebasestorage.googleapis.com/v0/b/manual-shopify.appspot.com/o/curso-future-is%2FFrame-2-4.png?alt=media&token=49691a49-7481-419b-905d-b59a4a0bf6f5" alt=""/>
        </li>
        <li className="LateralMenu__Center">
          {props.user && <h3>¡Bienvenido {props.user.name}!</h3>}
          <p>
            No dudes en compartir{" "}
            <span role="img" aria-label="Love Letter">
              💌
            </span>
            <br />
            <a
              href="https://www.facebook.com/sharer/sharer.php?u=manual.acromatico.co/landing"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook"></i>
            </a>{" "}
            |{" "}
            <a
              href="https://wa.me/?text=https%3A%2F%2Fmanual.acromatico.co"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-whatsapp"></i>
            </a>
          </p>
          <Button type="Primary" onClick={props.handleClear}>
            Salir
          </Button>
        </li>
        <li>
          {" "}
          <a href="#estrategia">1.- Estartegia de e-commerce.</a>{" "}
        </li>
        <li>
          <a href="#cuenta">2.- Abre tu cuenta y configura.</a>
        </li>

        {props.tienda !== "" && (
          <>
            <li>
              <a href="#productos">3.- Sube productos.</a>
            </li>
            <li>
              <a href="#pasarelas">4.- Medios de Pago.</a>
            </li>
            <li>
              <a href="#operacion">5.- Operación e-commerce.</a>
            </li>
          </>
        )}
      </ul>
    </section>
  );
};

export default LateralMenu;
