import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import './Login.scss';

import Card from '../../components/Card/Card';
import Button from '../../components/Button/Button';
import PopUpMessage from '../../components/PopUpMessage/PopUpMessage';

function Login(props) {
    const [password, setPassword] = useState("");
    const [PopUp, setPopUp] = useState({
        isOpen: false,
        title: '',
        message: <p></p>
    });

    useEffect(() => {
        if(props.clave){
            props.history.push('/manual');
        }
    }, [props]);
    

    const handleSubmit = (e) => {
        e.preventDefault();
        var url = 'https://local-delivery-app.acromati.co/mailgun/check-access-code';
        var data = {code: password};

        fetch(url, {
            method: 'PUT', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
        }).then(hola => hola.json())
        .then(respuesta => {
            if(respuesta.message === "No existe este código") {
                console.log('No se encontró la clave:', respuesta);
                setPopUp({
                    isOpen: true,
                    title: 'Error',
                    message: <p>No se encontró la clave de acceso, solicita una <Link to="/landing">aquí.</Link></p>
                });
            } else {
                props.setUser(respuesta);
            }
        })
        .catch(error => {
            console.log(error);
            setPopUp({
                isOpen: true,
                title: 'Error',
                message: <p>Error no identificado, intenta más tarde.</p>
            });
        });
    }

    return (
        <div className="Login__Container">
            <img src="/assets/images/logo-white.png" alt="Logo Acromático Development" />
            <div className="Login__Form-Container">
                <Card>
                    <div className="Login__Form">
                        <form className="ac-form" onSubmit={handleSubmit}>
                            <p>Ingresa la clave que te llegó por correo electrónico.</p>
                            <div className="ac-form-field">
                                <label htmlFor="clave">Clave</label>
                                <input type="text" name="clave" id="clave" placeholder="Clave de Entrada" onChange={(e) => setPassword(e.target.value)}/>
                            </div>
                            
                            <Button Submit type="Primary">Entrar</Button>
                        </form>
                        <p>¿Quieres una clave de entrada? <Link to="/landing">Registrate</Link></p>
                    </div>
                </Card>
            </div>
            <PopUpMessage
                isOpen={PopUp.isOpen}
                title={PopUp.title}
                handleClose={() => setPopUp({ isOpen: false, title: '', message: <p></p>})}>
                {PopUp.message}
            </PopUpMessage>
        </div>
    );
}

export default withRouter(Login);