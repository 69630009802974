import React, { useEffect, useState, useCallback } from "react";
import { withRouter } from "react-router-dom";
import "./Manual.scss";

import NavBar from "../../components/NavBar/NavBar";
import LateralMenu from "../../components/LateralMenu/LateralMenu";
import Button from "../../components/Button/Button";

function Manual(props) {
  const [mobileOpen, setMobileOpen] = useState(false);

  const [urlTienda, setUrlTienda] = useState("");

  const handleTiendaChange = useCallback((e) => {
    const valor = e.target.value;
    setUrlTienda(valor);
    localStorage.setItem("@tienda", valor);
  }, []);

  useEffect(() => {
    if (!props.clave) {
      props.history.push("/login");
    } else if (props.clave && !props.user) {
      var url =
        "https://local-delivery-app.acromati.co/mailgun/check-access-code";
      var data = { code: props.clave };

      const tienda = localStorage.getItem("@tienda");

      if (tienda) {
        setUrlTienda(tienda);
      }

      fetch(url, {
        method: "PUT", // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((hola) => hola.json())
        .then((respuesta) => {
          if (respuesta.message === "No existe este código") {
            props.handleClear();
            props.history.push("/login");
          } else {
            props.setUser(respuesta);
          }
        })
        .catch((error) => {
          console.log(error);
          props.history.push("/login");
        });
    }
  }, [props]);

  return (
    <div className="Manual__Container">
      <NavBar setMobileOpen={() => setMobileOpen(!mobileOpen)} />
      <LateralMenu
        open={mobileOpen}
        user={props.user}
        handleClear={props.handleClear}
        tienda={urlTienda}
      />
      <section className={`Manual__Main ${mobileOpen ? "open-menu" : ""}`}>
        <div className="Manual__Page" id="intro">
          <div className="Manual_Info">
            <img src="https://firebasestorage.googleapis.com/v0/b/manual-shopify.appspot.com/o/curso-future-is%2FCrear%20una%20tienda%20en%20li%CC%81nea%20en%20Shopify.001.jpeg?alt=media&token=f6fb3b43-e1f0-4580-9b6e-77124e374b95" alt=""/>
          </div>
        </div>
        <div className="Manual__Page" id="estrategia">
          <h2>Estartegia de e-commerce</h2>
          <div className="Manual_Info">
            <h3>Business Model Canvas</h3>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/manual-shopify.appspot.com/o/curso-future-is%2FCrear%20una%20tienda%20en%20li%CC%81nea%20en%20Shopify.003.jpeg?alt=media&token=fed17b1e-815a-4d2a-9ab4-a27924d50582"
              alt="Value Proposition Canvas"
            />
            <a
              href="https://firebasestorage.googleapis.com/v0/b/manual-shopify.appspot.com/o/curso-future-is%2FCrear%20una%20tienda%20en%20li%CC%81nea%20en%20Shopify.003.jpeg?alt=media&token=fed17b1e-815a-4d2a-9ab4-a27924d50582"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>
                Descargar Asset <span role="img">📁</span>
              </p>
            </a>
          </div>
        </div>
        <div className="Manual__Page" id="cuenta">
          <h2>Abre tu cuenta y configura</h2>
          <div className="Manual_Info">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/manual-shopify.appspot.com/o/curso-future-is%2FCrear%20una%20tienda%20en%20li%CC%81nea%20en%20Shopify.004.jpeg?alt=media&token=c3da101e-5be3-428c-b6a3-d02b94667d7b"
              alt="Abre tu cuenta"
            />
            <h3>Abre tu Cuenta</h3>
            <a
              href="https://www.shopify.com/?ref=lycklig-td"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button type="Primary">
                Abrir Cuenta de Shopify <span role="img">🛍️</span>
              </Button>
            </a>
            <h3>Ingresa la URL única de tu tienda</h3>
            <div className="ac-form-field">
              <input
                type="text"
                placeholder="<mi-tienda>.myshopify.com"
                value={urlTienda}
                onChange={handleTiendaChange}
              />
            </div>
            {urlTienda !== "" && (
              <>
                <h3>Entra a tu Cuenta</h3>
                <a
                  href={`https://${urlTienda}/admin`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button type="Primary">Entrar</Button>
                </a>
              </>
            )}
          </div>
        </div>
        {urlTienda !== "" && (
          <>
            <div className="Manual__Page" id="productos">
              <h2>Sube productos</h2>
              <div className="Manual_Info">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/manual-shopify.appspot.com/o/curso-future-is%2FCrear%20una%20tienda%20en%20li%CC%81nea%20en%20Shopify.005.jpeg?alt=media&token=798f3580-b12a-4e77-b588-b9ef17e4c4da"
                  alt=""
                />
                <h3>Aprende a Subir Productos</h3>
                <a
                  href={`https://${urlTienda}/admin/products`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button type="Primary">Ir a Sección</Button>
                </a>
                <h3>Descarga productos Ejemplo</h3>
                <a
                  href={`https://firebasestorage.googleapis.com/v0/b/manual-shopify.appspot.com/o/curso-future-is%2Fproducts_export.csv?alt=media&token=579b265e-f0b2-4086-b672-850e553aac80`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button type="Primary">Descargar</Button>
                </a>
                <h3>Sube los Productos</h3>
                <a
                  href={`https://${urlTienda}/admin/products`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button type="Primary">Ir a Sección</Button>
                </a>
              </div>
            </div>
            <div className="Manual__Page" id="pasarelas">
              <h2>Medios de Pago</h2>
              <div className="Manual_Info">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/manual-shopify.appspot.com/o/curso-future-is%2FCrear%20una%20tienda%20en%20li%CC%81nea%20en%20Shopify.006.jpeg?alt=media&token=3e5fa4d7-1b09-4e9a-bf00-654565ddcdea"
                  alt=""
                />
                <h3>Pasarelas de Pago</h3>
                <a
                  href="https://www.paypal.com/mx/webapps/mpp/merchant"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>PayPal</p>
                </a>
                <a
                  href="https://stripe.com/mx"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>Stripe</p>
                </a>
                <a
                  href="https://conekta.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>Conekta</p>
                </a>
                <a
                  href="https://www.openpay.mx/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>OpenPay</p>
                </a>
                <a
                  href="https://www.mercadopago.com.mx/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>Mercado Pago</p>
                </a>
                <h3>Conexión de Stripe y PayPal en Tienda</h3>
                <a
                  href={`https://${urlTienda}/admin/settings/payments`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button type="Primary">Ir a Conexión de Pasarelas</Button>
                </a>
              </div>
            </div>
            <div className="Manual__Page" id="operacion">
              <h2>Operación e-commerce</h2>
              <div className="Manual_Info">
                <h3>Flujo de e-commerce</h3>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/manual-shopify.appspot.com/o/curso-future-is%2FCrear%20una%20tienda%20en%20li%CC%81nea%20en%20Shopify.007.jpeg?alt=media&token=d592e060-a2fa-46fb-ba75-891aa78d4e65"
                  alt="Value Proposition Canvas"
                />
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/manual-shopify.appspot.com/o/curso-future-is%2FCrear%20una%20tienda%20en%20li%CC%81nea%20en%20Shopify.007.jpeg?alt=media&token=d592e060-a2fa-46fb-ba75-891aa78d4e65"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>
                    Descargar Asset <span role="img">📁</span>
                  </p>
                </a>
              </div>
            </div>
          </>
        )}
      </section>
    </div>
  );
}

export default withRouter(Manual);
