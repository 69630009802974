import React from 'react';
import './Card.scss';

function Card(props) {
    return (
        <div className="Card__Container" {...props}>
            {props.children}
        </div>
    );
}

export default Card;